import api from '@/services/ApiService'

class MonitoringService{
  async list() {
    const response = await api.get('/monitoring')
    return response.data
  }
  async MonitoringDetail(id) {
    const response = await api.get('/monitoring/' + id)
    return response.data
  }
}

export default new MonitoringService()
