<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        Administration <v-icon>mdi-chevron-right</v-icon> Projets > {{ this.graphs['project']['name'] }}
      </div>
      <div v-if="false">
        <router-link :to="'/projects/add'">
          <v-btn class="bg-primary btn"><v-icon>mdi-plus</v-icon> Ajouter</v-btn>
        </router-link>
      </div>
    </v-row>

    <v-row v-if="!loading">
      <v-col cols="8">
        <v-card class="d-flex p-0">
          <v-row>
            <div class="p-4" style="border-right:solid 1px rgb(233, 240, 252);padding:20px 30px;flex:1">
              <div style="color:#77787b;margin-bottom:5px;font-size:0.9em;text-transform:uppercase;margin-top:5px;">STATUS</div>
              <div class="chips-success" style="margin-left:-5px;" v-if="this.graphs['project']['loadStatut'] === 'up'">En ligne</div>
              <div class="chips-error" style="margin-left:-5px;" v-if="this.graphs['project']['loadStatut'] === 'down'">Hors ligne</div>
              <div class="chips-wait" style="margin-left:-5px;" v-if="this.graphs['project']['loadStatut'] === null">Non monitoré</div>
              <div style="color:#77787b;margin-top:10px;font-size:0.9em;">Statut du site</div>
            </div>
            <div class="p-4" style="border-right:solid 1px rgb(233, 240, 252);padding:20px 30px;flex:1">
              <div style="color:#77787b;margin-bottom:5px;font-size:0.9em;text-transform:uppercase;margin-top:5px;">Temps de réponse</div>
              <div style="color:#212121;font-size:1.3em;" class="mb-4">{{ this.graphs['project']['loadTime'] !== null ? this.graphs['project']['loadTime'] : '-' }} ms</div>
              <div style="color:#77787b;margin-top:10px;font-size:0.9em;">Temps requête HTTP</div>
            </div>
            <div class="p-4" style="border-right:solid 1px rgb(233, 240, 252);padding:20px 30px;flex:1">
              <div style="color:#77787b;margin-bottom:5px;font-size:0.9em;text-transform:uppercase;margin-top:5px;">TICKET YOUTRACK</div>
              <div style="color:#212121;font-size:1.3em;" class="mb-4">{{ this.graphs['project']['ticketCount'] }}</div>
              <div style="color:#77787b;margin-top:10px;font-size:0.9em;">nombre de ticket</div>
            </div>
            <div class="p-4" style="border-right:solid 1px rgb(233, 240, 252);padding:20px 30px;flex:1">
              <div style="color:#77787b;margin-bottom:5px;font-size:0.9em;text-transform:uppercase;margin-top:5px;">Merge request</div>
              <div style="color:#212121;font-size:1.3em;" class="mb-4">{{ this.graphs['project']['mrCount'] }}</div>
              <div style="color:#77787b;margin-top:10px;font-size:0.9em;">nombre de merge request</div>
            </div>
            <div class="p-4" style="border-right:solid 1px rgb(233, 240, 252);padding:20px 30px;flex:1">
              <div style="color:#77787b;margin-bottom:5px;font-size:0.9em;text-transform:uppercase;margin-top:5px;">Temps passé</div>
              <div style="color:#212121;font-size:1.3em;" class="mb-4">250 h</div>
              <div style="color:#77787b;margin-top:10px;font-size:0.9em;">temps total</div>
            </div>
          </v-row>

        </v-card>
        <v-card class="p-4 mt-4" style="max-height:300px;min-height:300px;padding-bottom:60px!important;">
          <div class="pt-2 pl-2" style="font-size:1em;text-transform:uppercase;margin-bottom:15px;">Monitoring du site </div>
          <LineChart :datas="graphs['datas']" :dates="graphs['dates']" />
        </v-card>



        <v-row class="mt-2">
          <v-col col="6">
            <v-card>
              <v-row>
                <v-col cols="12" class="d-flex mb-0 pb-0">
                  <div class="flex-grow-1 d-flex">
                    <v-icon style="font-size:1.3em;margin-top:8px;">mdi-jira</v-icon>
                    <div class="pt-2 pl-2" style="font-size:1em;text-transform:uppercase;">Youtrack</div>
                  </div>
                  <div>
                    <v-menu v-if="false">
                      <template v-slot:activator="{ props }">
                        <v-card v-bind="props">
                          <div class="d-flex" style="width:200px;">
                            <div class="flex-grow-1">
                              <div class="d-flex">
                                <div class="pr-3 pt-2">
                                </div>
                                <div>
                                  <div style="font-size:0.9em;color:#575757;">
                                    {{ this.youtrackStat === 1 ? 'Tous' : 'En dépassement' }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <v-icon style="font-size:1.5em;color:#575757;">mdi-chevron-down</v-icon>
                            </div>
                          </div>
                        </v-card>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in [{id: 1, name : 'Tous'}, {id: 2, name : 'En dépassement'}]" class="pl-0 pr-0 pt-0 pb-0">
                          <v-list-item-title class="item-list" @click="selectYoutrackStat(item)">
                            <div class="d-flex">
                              <div>
                                <div style="font-size:0.8em;color:#575757;">{{ item['name'] }}</div>
                              </div>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
                <v-col col="12">
                  <div class="d-flex mt-2 pb-4 pt-2 pl-3 pr-3 w-100" style="border-bottom:solid 1px rgb(233, 240, 252);font-size:0.9em;font-weight:bold;color:#000;">
                    <div style="flex:1;font-weight:bold; ">Ticket</div>
                    <div style="width:100px;font-weight:bold; ">Estimation</div>
                    <div style="width:100px;font-weight:bold; ">Temps passé</div>
                  </div>
                  <div class="mt-2 pb-4 pt-2 pl-3 pr-3 w-100" style="border-bottom:solid 1px rgb(233, 240, 252);" @click="openIssue(mergeRequest)" v-for="mergeRequest in projects['issues']">
                      <div class="d-flex">
                        <div class="flex-grow-1 d-flex">
                          <div class="d-flex w-100">
                            <div class="flex-grow-1">
                              <div class="d-flex">
                                <div style="color:#000">{{ mergeRequest['title'] }}</div>
                                <div class="ml-2" style="margin-top:-5px;"></div>
                              </div>

                              <div style="color:#959595;font-size:0.80em;">{{ mergeRequest['id'] }}</div>
                              <div style="font-size:0.9em;color:#999;padding-top:8px;padding-bottom:0;margin-bottom:-10px;">


                                   </div>
                            </div>
                            <div style="width:100px">
                              {{ (mergeRequest['estimate'] === null) ? '-' : calcTime(mergeRequest['estimate'], true) }}

                            </div>
                            <div style="width:100px;">
                              <v-tooltip text="Temps passé" location="bottom">
                                <template v-slot:activator="{ props }">
                                  <span v-bind="props">  {{ calcTime(mergeRequest['time'], true) }} </span>
                                </template>
                              </v-tooltip>
                            </div>

                          </div>
                        </div>
                        <div>

                        </div>
                      </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col col="6">
            <v-card>
              <v-row v-if="projects['mergeRequests'] !== undefined && projects['mergeRequests'].length === 0">
                <v-col col="12" class="w-100  mb-8 mt-8">
                  <div class="text-center">
                    <v-icon style="font-size:4em;">mdi-gitlab</v-icon>
                  </div>
                  <div class="text-center" style="font-size:0.9em;">Aucune merge request enregistré pour le moment
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-6"
                     v-if="projects['mergeRequests'] !== undefined && projects['mergeRequests'].length > 0">
                <v-col cols="12" class="d-flex pl-6 mb-0 pb-0">
                  <v-icon style="font-size:1.3em;margin-top:8px;">mdi-gitlab</v-icon>
                  <div class="pt-2 pl-2" style="font-size:1em;text-transform:uppercase;">Gitlab</div>
                </v-col>
                <v-col col="12">

                  <div v-for="mergeRequest in projects['mergeRequests']" class=" d-flex mt-2 pb-4 pt-2 pl-3 pr-3 " style="border-bottom:solid 1px rgb(233, 240, 252);">

                    <div style="flex:1;padding-left:0px;">
                      <div style="color:#000;font-size:0.9em;">{{ mergeRequest['name'] }}</div>
                      <div style="color:#888686;font-size:0.8em;">{{ mergeRequest['state'] }}</div>
                    </div>
                    <div>
                      <div v-if="mergeRequest['state'] ==='opened'" class="text-error d-flex" style="text-align:right;font-size:0.8em;"
                           v-for="error in mergeRequest['youtrack']['issue']['error']">
                        <div>
                          <v-icon style="font-size:1.8em;margin-top:8px;">mdi-alert</v-icon>
                        </div>
                        <div style="padding-top:10px;" class="pl-1"
                             v-if="error === 'BAD_STATUT' && error !== 'QUICK_MERGE'">{{
                            error === 'BAD_STATUT' ? 'Ticket non déplacé, status actuel : ' + mergeRequest['youtrack']['issue']['status'] : ''
                          }}
                        </div>
                        <div style="padding-top:10px;" class="pl-1" v-if="error === 'QUICK_MERGE'">
                          {{ error === 'QUICK_MERGE' ? 'Merge trop rapide' : '' }}
                        </div>
                      </div>
                      <div v-if="mergeRequest['youtrack']['issue'].length === 0 && mergeRequest['state'] ==='opened'" class="text-error d-flex"
                           style="text-align:right;font-size:0.8em;">
                        <div>
                          <v-icon style="font-size:1.8em;margin-top:8px;">mdi-alert</v-icon>
                        </div>
                        <div style="padding-top:10px;" class="pl-1">Pas de ticket</div>
                      </div>
                    </div>
                  </div>


                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>





      </v-col>
      <v-col cols="4">
        <v-card class="pt-4 pl-0 pr-0 pb-0">
          <div style="font-size:1.2em;color:#000;padding-left:20px;">Dernière activité</div>
          <div style="font-size:0.8em;color:#77787b;padding-left:20px;">Dernier évènements remontés sur le projet</div>
          <div class=" d-flex mt-0 pb-3 pt-1" style="border-bottom:solid 1px rgb(233, 240, 252);"></div>
          <div style="display:flex;justify-content: center;align-items: center;">
            <nodata v-if="this.graphs['project']['histories'].length === 0" title="Aucune données"></nodata>
          </div>


          <div v-for="event in this.graphs['project']['histories']" class=" d-flex mt-2 pb-4 pt-2 pl-3 pr-3 " style="border-bottom:solid 1px rgb(233, 240, 252);">
            <div  style="background:#e3f8ff;border-radius:50%;width:35px;height:35px;font-size:14px;display:flex;justify-content: center;align-items: center;">
              <v-icon v-if="event['key'] === 'project_repo_mr_add'">mdi-source-merge</v-icon>
              <v-icon v-if="event['key'] !== 'project_repo_mr_add'">mdi-sync</v-icon>
            </div>
            <div style="flex:1;padding-left:15px;">
              <div style="color:#131313;font-size:0.9em;">{{ event['desc'] }}</div>
              <div style="color:#888686;font-size:0.8em;">{{ moment(event['date']).format('DD/MM/YYYY HH:mm') }}</div>
            </div>
          </div>
        </v-card>

        <v-card class="pt-4 pl-0 pr-0 pb-0 mt-4">
          <div style="font-size:1.2em;color:#000;padding-left:20px;">Environnements</div>
          <div style="font-size:0.8em;color:#77787b;padding-left:20px;">Status des environnements</div>
          <div class=" d-flex mt-0 pb-3 pt-1" style="border-bottom:solid 1px rgb(233, 240, 252);"></div>
          <div v-for="env in this.graphs['project']['tags']" class=" d-flex mt-2 pb-4 pt-2 pl-3 pr-3 " style="border-bottom:solid 1px rgb(233, 240, 252);">
            <div style="flex:1;padding-left:15px;">
                <div class="d-flex">
                  <div style="flex:1;">
                    <div style="color:#131313;flex:1;">{{ env['name'] }}</div>
                    <div style="color:#888686;font-size:0.8em;">{{ env['version'] === 'Aucune version' ? '-' : env['restart'] }}</div>
                  </div>
                  <div style="color:#575757;font-size:0.9em;display:flex;justify-content: center;align-items: center;">{{ env['version'] }}</div>
                </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import ProjectsService from "@/services/ProjectsService.js";
import Loader from "@/components/ui/Loader.vue";
import BarChart from '@/components/ui/BarChart.vue';
import LineChart from '@/components/ui/LineChart.vue';
import MonitoringService from '@/services/MonitoringService.js';
import Nodata from '@/components/ui/NoData.vue';
moment.locale('fr');

export default {
  name: 'ProjectViewPage',
  computed: {
    fr() {
      return fr
    }
  },
  components: { Nodata, LineChart, BarChart, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: true,
      projects: [],
      project: [],
      events : [1, 2,3 ,4],
      envs : [{'name' : 'Developpement', 'restart' : '2024-11-14 08:00', 'version' : 'dev'}, {'name' : 'Recette', 'restart' : '2024-11-14 08:00', 'version' : 'REC-1.0.1'},{'name' : 'Productions', 'restart' : '2024-11-14 08:00', 'version' : 'PROD-1.0.0'}],
      graphs: {'datas' : [], 'dates' : []},
      date: new Date(),
      moment : moment,
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadProjects() {
      this.loading = true

      await ProjectsService.projectView(this.$route.params.id).then(response => {
        this.graphs['project'] = response['projects'];

        if(this.graphs['project']['website']['id'] !== undefined) {
          MonitoringService.MonitoringDetail(this.graphs['project']['website']['id']).then(responseMonitoring => {
            this.graphs['datas'] = responseMonitoring['datas'];
            this.graphs['dates'] = responseMonitoring['dates'];
            this.loading = false
          })
        } else {
        }

      })

      await ProjectsService.projectsStat(this.$route.params.id, this.youtrackStat).then(response => {
        console.log(response);
        this.projects = response['projects'];
        this.chartMr = response['projects']['charts']['mr'];
      });


      this.loading = false;


    },
    calcTime(time, full = false, dayInfo = false) {
      if (time > 60) {
        let heure = Math.floor(time / 60);
        let min = time - (heure * 60);

        let out = '';
        if (heure > 0) {
          out = heure + 'h'

          let minRest = time - heure * 60;

          if(minRest > 0) {
            out = out + ' ' + minRest

            if(full) {
              out = out;
            }
          } else if(minRest === 0) {
            if(full) {
              out = out + '00';
            }
          }
        }

        if (min > 0 && heure === 0) {
          out = (out === '') ? min + ' min' : out + ' ' + Math.floor(min) + ' min'
        }

        return out;
      } else {
        return time + ' min'
      }
    },
    async deleteUser(id) {
      let check = confirm('Etes vous sure ?')

      if(check) {
        ProjectsService.projectDelete(id).then(() => {
          useSnackbarStore().openSnackbar({
            text: 'Projet supprimé avec succès',
            color: 'success'
          })
          this.loadProjects();
        });
      }

    },
    editUser(id) {
      this.$router.push({ name: 'ProjectsEdit', params: {id : id} });
    }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true;
    this.loadProjects();
  }

}
</script>


<style scoped>
</style>
