<template>
  <Line
      style="width:100%;" id="my-chart-id" :data="chartData" :options="chartOptions" />
</template>

<script>
import { Bar, Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  registerables,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js'
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

ChartJS.register(...registerables);

export default {
  name: 'LineChart',
  components: { Bar, Line },
  props: {
    datas : {
      type : Array,
      required: true
    },
    dates : {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      moment: moment,
      chartData: {
        labels: this.dates,
        datasets: [
          {
            data: this.datas,
            borderColor: 'rgb(48, 167, 109)',
            borderWidth: 0,
            pointRadius: 0,
            tension:0.3,
            fill: true, // Remplit l'espace sous la ligne
            backgroundColor: this.createGradient() // Appelle la fonction pour le dégradé
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            enabled: true,
            intersect: false, // Permet d'afficher les légendes sans besoin de points
            mode: 'index', // Suivi sur toute la ligne
            position: 'nearest', // Place la légende au point le plus proche
            callbacks: {
              label: function (tooltipItem) {
                return `Valeur: ${tooltipItem.raw} ms`; // Personnalise l'affichage de la légende
              }
            }
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function (value, index) {
                let test = moment(this.getLabelForValue(value)).format('HH:mm');
                return index % 2 === 0 ? test : "";
              },
              maxRotation: 0,
              minRotation: 0,
             // stepSize: 10
            },
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return value + ' ms';
              },
              maxRotation: 0,
              minRotation: 0,
            },
            grid: {
              lineWidth: 0.3,
              color: 'rgb(224,224,224)',
              drawTicks: false
            }
          }
        }
      }
    }
  },
  methods: {
    createGradient() {
      return (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null;
        }

        // Création d'un dégradé vertical
        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, 'rgba(48, 167, 109, 1)'); // Couleur en haut
        gradient.addColorStop(1, 'rgba(48, 167, 109, 0.1)'); // Couleur en bas

        return gradient;
      };
    },
    updateChartData() {
      this.chartData.labels = this.dates;
      this.chartData.datasets[0].data = this.datas;
    }
  },
  watch: {
    datas() {
      this.updateChartData();
    },
    dates() {
      this.updateChartData();
    }
  },
};
</script>
