import api from '@/services/ApiService'

class ProjectsService{
  async projectsList() {
    const response = await api.get('/projects')
    return response.data
  }
  async projectDetail(id) {
    const response = await api.get('/projects/' + id)
    return response.data
  }
  async projectAdd(post) {
    const response = await api.post('/projects/add', post)
    return response.data
  }
  async projectUpdate(id, post) {
    const response = await api.put('/projects/' + id, post)
    return response.data
  }
  async projectDelete(id) {
    const response = await api.delete('/projects/' + id)
    return response.data
  }
  async projectsListForSelect() {
    const response = await api.get('/projects/select')
    return response.data
  }
  async projectsYoutrack() {
    const response = await api.get('/youtrack/projects')
    return response.data
  }
  async projectsStat(projectId, youtrack) {
    const response = await api.get('/projects/stats/' + projectId + '?youtrack=' + youtrack)
    return response.data
  }
  async gitlabRepoList(projectId = null) {
    let url = '/gitlab/repo';

    if(projectId !== null) {
      url += '?projectId=' + projectId;
    }

    const response = await api.get(url)
    return response.data
  }
  async gitlabRepositories() {
    let url = '/repositories';
    const response = await api.get(url)
    return response.data
  }
  async gitlabVariable(projectId) {
    let url = '/repositories/' + projectId + '/variables';
    const response = await api.get(url)
    return response.data
  }
  async gitlabVariableUpdate(projectId, key, value, type) {
    let url = '/repositories/' + projectId + '/variables/' + key;
    const response = await api.put(url, {value : value, type: type})
    return response.data
  }
  async gitlabVariableCreate(projectId, key, value, type) {
    let url = '/repositories/' + projectId + '/variables';
    const response = await api.post(url, {key : key, value : value, type : type})
    return response.data
  }
  async gitlabVariableDelete(projectId, key) {
    let url = '/repositories/' + projectId + '/variables/' + key;
    const response = await api.delete(url)
    return response.data
  }

  ///////// NEW ////////
  async projectView(projectId) {
    let url = '/projects/view/' + projectId;
    const response = await api.get(url)
    return response.data
  }

}

export default new ProjectsService()
