<template>
  <div style="padding:30px">
    <div>
      <img :src="noDataPicture" alt="no data" width="200" />
    </div>
    <div>
      {{  this.$props.title }}
    </div>
  </div>

</template>

<script>
import noDataPicture from '@/assets/noData.svg'


export default {
  name: 'Nodata',
  components: {noDataPicture},
  props: {
    title : {
      type : Array,
      required: true
    },
    subtitle : {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      noDataPicture: noDataPicture
    }
  }
};
</script>
